.currentStatus {
    font-family: var(--primaryFont);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3rem 0;
}

/* SLIDER STYLES */
.currentStatus--header h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;
}

.currentStatus--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;

    position: relative;
}

.currentStatus--body .quote {
    font-size: 4rem;
    position: absolute;
    z-index: 10;
    opacity: 0.5;
}

.currentStatus--body button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;

    appearance: none;
    outline: none;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;
}

.currentStatus--body .prevBtn {
    left: 5%;
    cursor: pointer;
}

.currentStatus--body .nextBtn {
    right: 5%;
    cursor: pointer;
}

.single--currentStatus {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    border-bottom-left-radius: 145px;
    border-top-left-radius: 145px;
}

.currentStatus--slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
}

.currentStatus--container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
}

.currentStatus--img {
    border: 10px solid;
    border-radius: 100%;
    background-size: cover;
    width: 300px;
    height: 300px;
    /*box-shadow: -80px -20px 0px #e9ad35, -80px 20px 0px #e9ad35;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%;
}

.currentStatus--img img {
    width: 100%;
}

.currentStatus--content {
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    flex: 1 1 0;
}

#currentStatusResidingLocation iframe {
    max-width: 700px;
    position: relative;
    margin-top: -46px;
}

#currentStatusResidingLocation .currentStatus--html {
    overflow: hidden;
    border-radius: 10px;
}

.currentStatus--content p {
    font-style: italic;
    font-weight: 300;
    margin-bottom: 1rem;
    width: 100%;
}

.currentStatus--content h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.currentStatus--content h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
}

@media (max-width: 992px) {
    .single--currentStatus {
        padding: 3rem 1rem;
        border-radius: 0;
    }

    .currentStatus--container {
        flex-direction: column;
        align-items: center;
    }

    .currentStatus--img {
        box-shadow: none;
    }

    .currentStatus--content {
        padding: 1rem 0 0;
    }
}
