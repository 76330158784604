.experience {
    min-height: 100vh;
}

.experience-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.experience-description {
    width: 100%;
    flex: 0.65;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 2rem;
}

.experience-description > h1 {
    font-size: 3.5rem;
    font-family: var(--primaryFont);
    margin-bottom: 2rem;
}

.experience-card:first-of-type {
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.experience-card:nth-of-type(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}


.experience-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    height: auto;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    transition: background-color 200ms ease-in-out;
}

.expcard-img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.expcard-img > img {
    width: 36px;
    pointer-events: none;
}

.experience-details {
    margin-left: 0.6rem;
    display: flex;
    flex-direction: column;

}

.experience-details > h6 {
    font-family: var(--primaryFont);
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 4px;
}

.experience-details > span {
    font-family: var(--primaryFont);
    font-size: 0.85rem;
    font-weight: 500;
    color: #000;
}

.experience-details > h4 {
    font-family: var(--primaryFont);
    font-size: 1.225rem;
    font-weight: 600;
    text-transform: uppercase;
}

.experience-details span.teams h5 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.experience-details span.teams {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border: 1px dotted;
    margin: 0.50rem 0;
    padding: 0.7rem;
    border-radius: 0.25rem;
}

.experience-details span.team {
    border-radius: 3px;
    padding: 3px;
    margin: 0 3px 3px 0;
    font-size: 0.75rem;
    text-align: center;
    font-family: monospace;
    border: 1px solid;
    white-space: nowrap;

}


.experience-details > h5 {
    font-family: var(--primaryFont);
    font-size: 1rem;
    font-weight: 500;
}

.experience-image {
    box-sizing: border-box;
    padding: 1rem;
    flex: 0.35;
    margin-left: 7%;
    margin-top: 1rem;
    pointer-events: none;
}

.experience-image > img {
    width: 100%;
    pointer-events: none;
}

@media (min-width: 992px) and (max-width: 1380px) {
}

@media screen and (max-width: 992px) {
    .experience {
        min-height: 100%;
    }

    .experience-image {
        display: none;
    }

    .experience-description {
        flex: 1;
        margin: auto;
    }

    .experience-description > h1 {
        align-self: center;
        font-size: 3.2rem;
    }

    .experience-card {
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    .experience-description > h1 {
        font-size: 3rem;
        margin-bottom: 2rem;
        align-self: center;
    }

    .experience-description {
        padding: 1rem;
    }

    .experience-card {
        padding: 1rem;
        margin-bottom: 1.5rem;
    }

    .expcard-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }

    .expcard-img > img {
        width: 30px;
    }

    .experience-details > h6 {
        font-size: 0.85rem;
        margin-bottom: 0.45rem;
    }

    .experience-details > h4 {
        font-size: 1.125rem;
        line-height: 126%;
    }

    .experience-details > h5 {
        font-size: 1.05rem;
        line-height: 126%;
    }
}

@media screen and (max-width: 600px) {
    .experience-description > h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
